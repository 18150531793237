.resource-grid {

    .ctn-main {
        padding-left: 40px;
        padding-right: 40px;
    }

    .filters {
        border-top: 2px solid $color-main;
        margin-top: 60px;
        margin-bottom: 60px;

        @include breakpointMax($smaller-devices) {
            padding-top: 8px;
            padding-bottom: 28px;
            border-top: 0;
            margin-bottom: 40px;
        }

        .filter-toggle {
            color: $color-deep-dark;
            font-size: 18px;
            line-height: 110%;
            /* 19.8px */
            letter-spacing: -0.36px;
            text-transform: uppercase;
            position: relative;
            padding-bottom: 8px;
            border-bottom: 1px solid $color-main;

            &:after {
                content: "";
                position: absolute;
                width: 24;
                height: 24;
                right: 12px;
                bottom: 0;
                background: url(assets/img/icon-chevron.svg) no-repeat center center;
                transform: rotate(180deg);
                transition: transform 0.25s ease-out;
            }
        }

        .filter-toggle:has(+ .-hide):after {
            transform: rotate(360deg);
        }

        ul {
            display: flex;
            list-style: none;
            justify-content: space-between;

            @include breakpointMax($smaller-devices) {
                display: block;
                transform: translate(0, 20px);
            }
        }

        li {
            border: 0px solid $color-main;
            padding: 18px 5px 0 25px;
            transform: translateY(-8px);

            &.active {
                border-top-width: 8px;
                padding-top: 10px;
                color: $color-main;
            }
            &:hover {
                color: $color-main;
            }

            @include breakpointMax($smaller-devices) {
                border: none;
                background-color: $color-light-blue;
                margin-bottom: 6px;
                padding: 12px;
                padding-left: 29px;
                border-radius: 4px;

                &.active {
                    color: $white;
                    background-color: $color-main;
                }
            }
        }

        .separator {
            border-left: 2px solid $color-main;
            transform: none;
            max-height: 30px;

            @include breakpointMax($smaller-devices) {
                display: none;
            }
        }
    }

    .filter-item {
        cursor: pointer;
        color: $color-darker-blue;
        @include font-size(1.5);
        line-height: 110%;
        /* 16.5px */
        letter-spacing: -0.3px;
        text-transform: uppercase;
        position: relative;
        margin-left: 0;
        transition: color 0.5s ease-out;

        &:before {
            content: "";
            position: absolute;
            width: 16.5px;
            height: 16.5px;
            left: 5px;
            bottom: 1px;
            opacity: 1;
            transition: opacity 0.5s ease-out;

            @include breakpointMax($smaller-devices) {
                top: 11px;
                left: 10px;
            }
        }
        //only on desktop
        @include breakpointMin($smaller-devices-up) {
            &:hover:before,
            &.active:before {
                opacity: 0;
            }
        }

        &:after {   
            content: "";
            position: absolute;
            width: 16.5px;
            height: 16.5px;
            left: 5px;
            bottom: 1px;
            opacity: 0;
            transition: opacity 0.5s ease-out;
        }

        //only on desktop
        @include breakpointMin($smaller-devices-up) {
            &:hover:after,
            &.active:after {
                opacity: 1;
            }
        }
        //only on mobile
        &.active::before {
            top: 9.5px
        }

        @include breakpointMax($smaller-devices) {
            @include font-size(1.4);
            line-height: 15.4px;
            letter-spacing: -0.28px;
            padding-left: 10px;
        }

        &.no-icon {
            padding-left: 5px;

            @include breakpointMax($smaller-devices) {
                padding-left: 10px;
            }

            &:before {
                display: none;
            }
        }

        &.financial-empowerment {
            &::before {
                background: url(assets/img/icon-filter-arch.svg) no-repeat center center;
            }
            &::after {
                background: url(assets/img/icon-filter-arch-blue.svg) no-repeat center center;
            }
        }

        &.embracing-self-worth {
            &::before {
                background: url(assets/img/icon-filter-oval.svg) no-repeat center center;
            }
            &::after {
                background: url(assets/img/icon-filter-oval-blue.svg) no-repeat center center;
            }
        }

        &.community-connection {
            &::before {
                background: url(assets/img/icon-filter-hourglass.svg) no-repeat center center;
            }
            &::after {
                background: url(assets/img/icon-filter-hourglass-blue.svg) no-repeat center center;
            }
        }

        &.showing-up-confidently {
            &::before {          
                background: url(assets/img/icon-filter-leaf.svg) no-repeat center center;
            }
            &::after {
                background: url(assets/img/icon-filter-leaf-blue.svg) no-repeat center center;
            }
        }

        &.location {
            &::before {           
                background: url(assets/img/icon-filter-map.svg) no-repeat center center;
            }
            &::after {
                background: url(assets/img/icon-filter-map-blue.svg) no-repeat center center;
            }
        }
       
    }

    .--grid-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        //grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        justify-content: space-evenly;
        justify-items: center;
        align-items: start;

        margin-bottom: 60px;
        min-height: 660px;

        @include breakpointMax($smaller-devices) {
            display: block;
        }
    }

    .grid-item {
        display: flex;
        padding: 24px 16px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;
        max-width: 777px;
        min-height: 612px;
        background-color: $color-sandy-gray;
        position: relative;
        transition: background-color .5s ease-out, color .5s ease-out;

        .--icon.--white {
            display: none;
        }

        .filter-item {
            transition: color .5s ease-out;
        }

        .cta-arrow {
            transition: color .5s ease-out, border-color .5s ease-out;

            &:after {
                background-size: contain;
                opacity: 1;
                transition: opacity .5s ease-out, background .5s ease-out;
            }                    
        }

        //hover effect only on desktop
        @include breakpointMin($smaller-devices-up) {
            &:hover {
                color: $color-light-purple;
                background-color: $color-main;

                .--icon.--white {
                    display: block;
                    background-color: $color-main;
                }

                .--icon {
                    display: none;
                }

                .filter-item {
                    color: $white;

                    &::after {
                        opacity: 1;
                        //transition: opacity .2s ease-out, background .2s ease-out;
                    }
                }

                .financial-empowerment::after {
                    background: url(assets/img/icon-filter-arch-white.svg) no-repeat center center;
                }

                .embracing-self-worth::after {
                    background: url(assets/img/icon-filter-oval-white.svg) no-repeat center center;
                }

                .community-connection:after {
                    background: url(assets/img/icon-filter-hourglass-white.svg) no-repeat center center;
                }

                .showing-up-confidently:after {
                    background: url(assets/img/icon-filter-leaf-white.svg) no-repeat center center;
                }

                .location::after {
                    background: url(assets/img/icon-filter-map-white.svg) no-repeat center center;
                }

                .cta-arrow {
                    color: $color-light-purple;
                    border-color: white;

                    &:after {
                        background-size: contain;
                        background: url(assets/img/icon-arrow-white.svg) no-repeat center center;
                        opacity: 1;
                        transition: background .5s ease-out, opacity .5s ease-out;
                    }
                }
            }
        }

        @include breakpointMax($smaller-devices) {
            margin-bottom: 12px;
            height: auto;
            min-height: auto;
        }

        &.--1-wide {
            grid-column: span 1;
            border-radius: 40px 40px 0 0;

            .--leaf {
                border-radius: 0 40px;
                width: calc(100% + 32px);
                max-width: calc(100% + 32px);
                margin: -24px -16px 24px;

                @include breakpointMin($smaller-devices-up) {
                    height: 191px;
                }
            }
        }

        &.--2-wide {
            grid-column: span 2;
            padding: 24px;
            border-radius: 80px 80px 0 0;

            .--leaf {
                border-radius: 0 80px;
                width: calc(100% + 48px);
                max-width: calc(100% + 48px);
                margin: -24px -24px 24px;

                @include breakpointMin($smaller-devices-up) {
                    height: 352px;
                }
            }
        }

        h3 {
            font-size: 32px;
            letter-spacing: -1.6px;
            line-height: normal;
            flex: 1 0 0;
            align-self: stretch;
            margin-top: 12px;

            @include breakpointMax($smaller-devices) {
                font-size: 24px;
                letter-spacing: -1.2px;
                margin-bottom: 10px;
            }
        }

        .cta-arrow {
            font-family: "Marshalls-Sans-Medium";
            font-size: 18px;
            line-height: normal;
            letter-spacing: -0.36px;
            text-transform: uppercase;

            p {
                margin: 0;
                display: inline-block;
                border-bottom: 1px solid transparent;
                transition : border-bottom .5s ease-out;
            }
 
            &:after {
                transform: translateX(0px);
                transition: transform .5s ease-out !important;
            }

            //hover effect only on desktop
            @include breakpointMin($smaller-devices-up) {
                &:hover {
                    p {
                        border-bottom: 1px solid $white;
                    }
                }

                &:hover:after {
                    transform: translateX(-10px);
                }
            }

            @include breakpointMax($mid-devices) {
                font-size: 16px;
                letter-spacing: -0.32px;
            }
        }

        .filter-item {
            padding-left: 20px;
            cursor: default;

            &::before,
            &::after {
                left: 0px;
                bottom: 0px;
                top: 0px;
            }

            &.no-icon {
                padding-left: 0px;
            }

            &.location {
                display: none;
            }
        }

        img.--leaf {
            width: 100%;
            max-height: 352px;
            object-fit: cover;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                right: 10px;
                bottom: 10px;
                width: 24px;
                height: 24px;
                padding: 6px;
                background-color: $white;
                border-radius: 100px;
                cursor: pointer;
                background: url(assets/img/icon-play.svg) no-repeat center center;
            }
        }

    }


    .footer {
        text-align: center;
        margin-bottom: 60px;
    }

    .grid-item div:not(.cta-group) a {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            right: -5px;
            bottom: 38px;
            width: 24px;
            height: 24px;
            background: url(assets/img/icon-play.svg) no-repeat center center;
            background-color: $white;
            border-radius: 100px;
            cursor: pointer;
            transition: background-image .5s ease-out, background-color .5s ease-out; 
        }
    }

    .grid-item:hover div:not(.cta-group) a {
        &:after {
            background-image: url(assets/img/icon-play-white.svg);
            background-color: $color-main;
        }
    }
}

