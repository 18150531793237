.mission-story {
    color: $color-main;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    strong {
        font-family: 'Marshalls-Serif-Bold-Italic';
    }

    .ctn-main {
        background-color: $color-light-blue;
        padding: 40px;

        .story-wrapper {
            background-color: $color-bg-offwhite;
            padding: 100px;

            @include breakpointMax($mobile) {
                display: block;
            }

            h2 {
                @include font-size(5.2);
                line-height: normal;
                letter-spacing: -2.6px;
                font-family: 'Marshalls-Sans-Light';
            }

            h1 {
                @include font-size(8.6);
                line-height: 86px;
                letter-spacing: -4.3px;
            }

            h3 {
                @include font-size(3.2);
                line-height: normal;
                letter-spacing: -1.6px;
                font-family: 'Marshalls-Sans-Light';
            }

            p {
                color: $color-deep-dark;
                @include font-size(1.8);
                line-height: 19.8px;
                letter-spacing: -0.36px;
            }
        }

        .first-section {
            display: flex;
            justify-content: space-around;
            align-items: center;

            .left-text {
                width: 50%;
            }

            .right-image {
                position: relative;

                .ellipse {
                    position: absolute;
                    top: -126px;
                    left: -92px;
                    z-index: 1;
                    width: 184px;
                    height: 252px;
                }

                .main-img {
                    position: relative;
                    z-index: 2;
                    width: 378px;
                    height: auto;
                }

            }
        }

        .second-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            transform: translateY(-100px);

            .left-image {
                position: relative;
                margin-left: 100px;

                .ellipse {
                    position: absolute;
                    top: 200px;
                    left: -112px;
                    z-index: 1;
                    width: 225px;
                    height: 208px;
                }

                .main-img {
                    position: relative;
                    z-index: 2;
                    width: 432px;
                    height: auto;
                    border-radius: 220px;
                }
            }

            .right-text {
                width: 50%;
            }
        }

    }

}