/* -------------------------------------------------------------------------*/
/* Mixins */

@mixin button($back-color, $front-color, $hover-color: $front-color, $hover-text: $back-color) {

    &,
    &:visited {
        display: inline-block;
        position: relative;
        border: 1px solid transparent;
        background-color: $back-color;
        cursor: pointer;
        color: $front-color;
        text-transform: uppercase;
        text-decoration: none;
        font-family: $font-accent;
        line-height: 18px;
        transition: all 0.3s ease-in-out;
        appearance: none;
        @include font-size(1.8);
        padding: 16px 24px;
        border-radius: 100px;
        white-space: nowrap;

        @include breakpointMax($mobile) {
            padding: 12px 20px;
            line-height: 16px;
            @include font-size(1.6);
            letter-spacing: -0.32px;
        }
    }

    &:active,
    &:focus,
    &:hover {
        color: $hover-text;
        text-decoration: none;
        @include breakpointMin($smaller-devices-up) {
            border: 1px solid $hover-color;
            background-color: $hover-color;
        }
    }
}

// Clearfix
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

/*
-container must be display: flex (childs will be as height as the biggest one)
-child should be float:left, width: 50%
-text-inside should be @mixin half-container(right/left)
-the rest is styling.
*/
@mixin half-container($position: "left") {
    width: calc(#{$container-width} / 2);
    max-width: 100%;

    @if $position =="left" {
        float: right; //the text is aligned to the right in the half-container on the left
    }

    @else if $position =="right" {
        float: left; //the text is aligned to the left in the half-container on the right
    }
}

// Styling Select boxes
@mixin selectbox-style {
    height: 30px;
    border-radius: 0;
    border-color: $color-border;
    background-color: $color-bg-body;
    color: #fff;
    font-size: $font-size;
}

// Rem output with px fallback
@mixin font-size($sizeValue: 1.6) {
    font-size: ($sizeValue * 10) + px;
    font-size: $sizeValue + rem;
}

// Box model
@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model; // Safari <= 7
    -moz-box-sizing: $box-model; // Firefox <= 19
    box-sizing: $box-model;
}


@mixin breakpointMin($size) {
    @media only screen and (min-width: $size) {
        @content;
    }
}

@mixin breakpointMax($size) {
    @media only screen and (max-width: $size) {
        @content;
    }
}

// Flicker animation for loading text
@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// Pulse
@keyframes pulseAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes pulseAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

// hot to use fadeInFromNone
//animation: fadeInFromNone 0.5s ease-out ;
@keyframes fadeInFromNone {
    0% {
        opacity: 0;
        display: none;
    }

    1% {
        opacity: 0;
        display: block;
    }

    100% {
        opacity: 1;
        display: block;
    }
}

@-webkit-keyframes bounce {

    from,
    10%,
    26.5%,
    40%,
    50%,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    20%,
    21.5% {
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -20px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    35% {
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -10px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    45% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -3px, 0);
    }
}

@keyframes bounce {

    from,
    10%,
    26.5%,
    40%,
    50%,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    20%,
    21.5% {
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -20px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    35% {
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -10px, 0);
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    45% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -3px, 0);
    }
}


@-webkit-keyframes bounceLeft {

    from,
    10%,
    26.5%,
    40%,
    50%,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    20%,
    21.5% {
        -webkit-transform: translate3d(-30px, 0, 0);
        transform: translate3d(-20px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    35% {
        -webkit-transform: translate3d(-15px, 0, 0);
        transform: translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    45% {
        -webkit-transform: translate3d(-4px, 0, 0);
        transform: translate3d(-3px, 0, 0);
    }
}

@keyframes bounceLeft {

    from,
    10%,
    26.5%,
    40%,
    50%,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    20%,
    21.5% {
        -webkit-transform: translate3d(-30px, 0, 0);
        transform: translate3d(-20px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    35% {
        -webkit-transform: translate3d(-15px, 0, 0);
        transform: translate3d(-10px, 0, 0);
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    45% {
        -webkit-transform: translate3d(-4px, 0, 0);
        transform: translate3d(-3px, 0, 0);
    }
}

.bounceLeft {
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-name: bounceLeft;
    animation-name: bounceLeft;
}