.event-listing {

    padding-top: 20px;
    padding-bottom: 80px;
    margin: 0 auto;
    background-color: $color-bg-offwhite;

    @include breakpointMax($smaller-devices) {
        padding-top: 0;
        padding-bottom: 40px;
    }

    .ctn-main {
        padding-left: 40px;
        padding-right: 40px;

        @include breakpointMax($smaller-devices) {
            padding-left: 20px;
            padding-right: 20px;
        }

        &>div {
            display: flex;
            justify-content: space-between;

            @include breakpointMax($mid-devices) {
                display: block;
            }

            @include breakpointMax($smaller-devices) {
                display: flex;
                flex-direction: column;
                gap: 60px;
            }
        }

        .event-card-container {
            display: flex;
            flex-wrap: wrap;
            gap: 50px;

            @include breakpointMax($smaller-devices) {
                display: block;
                width: auto;
                margin: 0 auto;
            }
        }

        .event-card {
            background-color: $color-light-purple;
            display: flex;
            max-width: 444px;
            min-height: 506px;
            padding: 24px 20px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 40px;
            flex: 1 1 20%;
            transition: background-color 0.5s ease-out;

            .tag {
                transition: color 0.5s ease-out;
            }
            .card-body {
                transition: color 0.5s ease-out, border-top-color 0.5s ease-out;
            }
            .sub-head {
                transition: color 0.5s ease-out;
            }
            .card-footer {
                transition: color 0.5s ease-out, border-top-color 0.5s ease-out;
                .cta-arrow  {
                    transition: color 0.5s ease-out, text-decoration 0.5s ease-out;
                    &::after {
                        transition: background-image 0.5s ease-out;
                    }
                }
            }


            &:first-child {
                margin-left: 0;

                @include breakpointMax($smaller-devices) {
                    margin: 20px 0px;
                }
            }

            @include breakpointMax($smaller-devices) {
                margin: 20px 0px;
                width: auto;
            }

            &.--disabled {
                .cta-group {
                    pointer-events: none;
                    opacity: .5;
                }

                .cta-arrow:after {
                    display:none;
                }
            }

            &:not(.--disabled):hover {
                background-color: $color-main;

                .tag {
                    color: $color-light-purple;
                }

                .card-header {
                    .tag:before {
                        background-image: url(assets/img/icon-filter-arch-purple.svg);
                        bottom: 0px;

                        @include breakpointMin($mobile-small) {
                            bottom: -1px;
                        }

                        @include breakpointMin($mobile) {
                            bottom: 2px;
                        }
                    }

                    .subhead {
                        color: white;
                    }
                }

                h3 {
                    color: $color-light-purple;
                }

                .card-body {
                    color: white;
                    border-top-color: white;

                    a { 
                        color: white;
                        text-decoration: underline;
                    }
                }

                .card-footer {
                    color: white;
                    border-top-color: white;

                    .cta-arrow {
                        color: white;
                        text-decoration: underline;

                        &:after {
                            background-image: url(assets/img/icon-arrow-white.svg);
                            transform: translateX(-10px);
                        }
                    }
                }
            }

            .card-header {
                width: 100%;
                flex: 1 0 60%;

                .tag {
                    position: relative;
                    padding-left: 25px;
                    transition: color 0.25s ease-out;

                    &:before {
                        content: "";
                        background: url(assets/img/icon-filter-arch-blue.svg) no-repeat center center;
                        position: absolute;
                        width: 16.5px;
                        height: 16.5px;
                        left: 5px;
                        bottom: 0px;
                        transition: background-image 0.25s ease-out;


                        @include breakpointMin($mobile-small) {
                            bottom: -1px;
                        }

                        @include breakpointMin($mobile) {
                            bottom: 2px;
                        }
                    }
                }

                img {
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                    margin-top: 20px;
                }

                .subhead {
                    color: $color-darker-blue;
                    font-size: 22px;
                    line-height: normal;
                    letter-spacing: -1.1px;
                    margin-top: 40px;
                    margin-bottom: 10px;
                    transition: color 0.25s ease-out;

                    @include breakpointMax($smaller-devices) {
                        font-size: 18px;
                        line-height: normal;
                        letter-spacing: -0.9px;
                    }
                }

                h3 {
                    transition: color 0.25s ease-out;

                    @include breakpointMax($smaller-devices) {
                        font-size: 28px;
                        line-height: normal;
                        letter-spacing: -1.4px;
                    }
                }
            }

            .card-body {
                flex: 1 0 15%;
                border-top: 2px solid $color-darker-blue;
                padding-top: 10px;
                color: $color-darker-blue;
                display: flex;
                transition: color 0.25s ease-out, border-color 0.25s ease-out;

                @include breakpointMax($smaller-devices) {
                    flex-direction: column;
                    gap: 12px;
                }

                p {
                    margin: 0;
                }

                h6 {
                    font-size: 22px;
                    line-height: normal;
                    letter-spacing: -1.1px;
                    margin-right: 20px;
                    font-family: 'Marshalls-Sans-Light';
                    transition: color 0.15s ease-out;

                    @include breakpointMax($smaller-devices) {
                        font-size: 18px;
                        line-height: normal;
                        letter-spacing: -0.9px;
                    }
                }

                p {
                    transition: color 0.15s ease-out;

                    @include breakpointMax($smaller-devices) {
                        font-size: 16px;
                        line-height: 17.6px;
                        letter-spacing: -0.32px;
                    }
                }
            }

            .card-footer {
                flex: 1 0 15%;
                width: 100%;
                border-top: 2px solid $color-darker-blue;
                padding-top: 10px;
                transition: border-color 0.35s ease-out;

                .cta-arrow {
                    color: $color-darker-blue;
                    font-family: 'Marshalls-Sans-Medium';
                    font-weight: 600;
                    transition: color 0.25s ease-out, transform 0.25s ease-out;
                    padding-right: 20px;

                    @include breakpointMax($mobile-xxxsmall) {
                        max-width: 200px;
                    }

                    &:after {
                        background-image: url(assets/img/icon-arrow-navy.svg);
                        transition: background-image 0.25s ease-out;

                        @include breakpointMax($mobile-xxxsmall) { 
                            right: -40px;
                        }
                    }
                }
            }


        }
    }

}
