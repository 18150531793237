.site-footer {
    background-color: $color-main;
    color: $white;
    @include font-size(1.5);
    line-height: 15px;
    letter-spacing: -0.3px;

    // @include breakpointMax($smaller-devices) {
    //     padding-top: 35px;
    // }

    .ctn-main {
        padding: 40px;

        @include breakpointMax($smaller-devices) {
            padding: 20px;
        }
    }

    .site-footer__ctn {
        display: flex;
        justify-content: space-between;

        @include breakpointMax($smaller-devices) {
            flex-direction: column;
            margin-top: 30px;
        }
    }

    .site-footer__nav {
        margin-top: 16px;
        line-height: 16.5px;
        @include font-size(1.5);
        letter-spacing: -0.3px;

        .bolder {
            font-family: 'Marshalls-Sans-Bold';
            @include font-size(1.8);
            line-height: 19.8px;
            letter-spacing: -0.36px;
            text-decoration: none;

            @include breakpointMax($smaller-devices) {
                @include font-size(1.6);
                line-height: 17.6px;
                letter-spacing: -0.32px;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            row-gap: 16px;

            @include breakpointMax($smaller-devices) {
                width: 100%;
            }

            li {
                display: inline-block;
            }

            a {
                color: $white;
                text-decoration: underline;
                transition: color 0.5s;

                &:hover {
                    //color: $black;
                }
            }
        }
    }

    .site-footer__right {
        text-align: right;

        @include breakpointMax($smaller-devices) {
            text-align: left;
            margin-top: 66px;
        }

        .site-footer__social {
            ul {
                display: flex;
                justify-content: flex-end;
                column-gap: 40px;
            }

            @include breakpointMax($smaller-devices) {
                ul {
                    justify-content: flex-start;
                }
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                margin: 0 5px 0 0;
                padding-top: 1px;
                //more align like mocks...
                padding-left: 1px;
                color: $white;
            }

            a[name="tiktok"] img {
                max-width: 24px;
            }

            img {
                display: block;
            }
        }
    }

    .site-footer__legal {
        text-align: right;
        margin-top: 66px;

        @include breakpointMax($smaller-devices-up) {
            text-align: left;
        }

        p {
            font-size: 15px;
            margin: 0;
        }
    }

}