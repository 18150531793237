.events-detail-text {
    background-color: $color-bg-offwhite;

    .--grid-container {
        padding: 80px 0;
        display: flex;
        max-width: 1010px;

        @include breakpointMax($smaller-devices) {
            display: block;
            padding: 20px;
            width: auto;
        }
    }

    sup {
        top: -0.5rem;
    }

    .grid-item.--left-section {
        width: 50%;
        padding-right: 125px;

        @include breakpointMax($smaller-devices) {
            width: 100%;
            margin-right: 0;
            padding-right: 0;
        }
    }

    .grid-item.--right-section {
        width: 50%;
        color: $color-darker-blue;
        padding-top: 80px;

        @include breakpointMax($smaller-devices) {
            padding-top: 0;
            width: 100%;
        }
    }

    div#Tock_widget_container>div.TockWidgetWrapper path {
        fill: var(--interactive-primary, #344ef4);
    }

    div#Tock_widget_container > div.TockWidgetWrapper .TockWidget-H1 {
        text-transform: uppercase;
    }

    div#Tock_widget_container>div.TockWidgetWrapper .TockButton-blue {
        background-color: $color-main;

        &:hover {
            background-color: $color-darker-blue;
        }
    }
}