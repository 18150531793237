/* Basic Layout Elements */

/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .paging-navigation,
/* Older / Newer Posts Navigation (always hidden) */
.infinite-scroll.neverending .site-footer {
    /* Theme Footer (when set to scrolling) */
    display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before */
.infinity-end.neverending .site-footer {
    display: block;
}

/* Text meant only for screen readers */
.screen-reader-text {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);

    &:hover,
    &:active,
    &:focus {
        z-index: 100000;
        /* Above WP toolbar */
        display: block;
        top: 5px;
        left: 5px;
        width: auto;
        height: auto;
        border-radius: 3px;
        box-shadow: 0 0 2px 2px rgba(200, 0, 0, 0.9);
        background-color: $color-bg-body;
        padding: 15px 23px 14px;
        color: $color-text-main;
        text-decoration: none;
        font-family: $font-accent;
        line-height: normal;
        clip: auto !important;
        @include font-size(1.4);
    }
}

.site-body {
    position: relative;
}

.site-content {
    position: relative;
}


/* Slider layout */
.col-ctn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &+.button {
        margin-top: 20px;

        @include breakpointMax($mobile) {
            margin-top: 0;
        }
    }
}

.col-1-3 {
    width: calc((100% / 3) - 10px);
}

.col-2-3 {
    width: calc((100% / 3 * 2) - 10px);
}

.col-1-2 {
    width: calc(50% - 10px);
}

.col-1-4 {
    width: calc(25% - 10px);
}

.col-3-4 {
    width: calc(75% - 10px);
}

.col-1-5 {
    width: calc(20% - 10px);
}

.col-2-5 {
    width: calc((100% / 5 * 2) - 15px);
}

.col-3-5 {
    width: calc((100% / 5 * 3) - 15px);
}

.col-slider {
    display: inline-block;
    padding: 0 10px;

    @include breakpointMax($mobile-small) {
        padding: 0;
    }
}

@include breakpointMax($mobile-small) {
    .ctn-main>.col-ctn {

        &>.col-1-3,
        &>.col-2-3,
        &>.col-1-2,
        &>.col-1-4,
        &>.col-3-4,
        &>.col-1-5,
        &>.col-2-5,
        &>.col-3-5 {
            width: 100%;
            //margin-bottom: 30px;
        }
    }

    .col-ctn .col-ctn {
        justify-content: space-between;
    }
}


/* Helpers: mobile and desktop only */
.mobile-only {
    display: none;
}

@include breakpointMax($mobile) {
    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }

    .mobile-first {
        order: 1 !important;
    }
}


/* Navigation */
.navigation {
    ul {
        margin: 0;
        list-style: none;
    }

    li {
        margin: 0;
    }
}


/* Sections */
section {
    // margin-bottom: 130px;

    // @include breakpointMax($mobile-small) {
    //   margin-bottom: 60px;
    // }

    &.main {
        margin-bottom: 0;
    }

    a:not(.button) {
        text-decoration: none;
    }

    /*
  a:not(.button):hover{
    background-color: $color-main;
    color: $white;
    text-decoration: none;
  }
  */
}

.subtitle {
    display: block;
    margin-bottom: 5px;
    font-family: $font-accent;
    line-height: 24px;
    @include font-size(2);

    @include breakpointMax($mobile) {
        line-height: 21px;
        @include font-size(1.8);
    }

    &+div {
        margin-top: 35px;

        @include breakpointMax($mobile) {
            margin-top: 15px;
        }
    }
}

.description {
    margin-top: 5px;

    //not the 2 col layout
    .ctn-main>& {
        padding-right: 70px;

        @include breakpointMax($mobile) {
            padding-right: 0;
        }
    }

}