.ctn-main {
    width: $container-width;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;

    &.-small {
        width: $container-small;
    }

    &.-large {
        width: $container-large;
    }

    &.-slider-ctn {
        width: $container-slider;
    }
}

.video-container {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;

    /* 16:9 */
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // height: 100%;
        //height: 612px;

        @include breakpointMin(426px) {
            height: 100%;
        }
    }
}

.-block {
    display: block;
    clear: both;
}

.-text-centered {
    text-align: center;
}

.-v-align {
    display: flex;
    align-items: center;
}

.-alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
}

.-alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
}

.-valign {
    display: flex;
    align-items: center;
}

.-centered {
    display: table;
    margin: 0 auto;
}

.-capitalize {
    ::first-letter {
        text-transform: uppercase;
    }
}

.-hide,
.-hidden {
    display: none !important;
}

.-desktop-only {
    display: block;

    @include breakpointMax($smaller-devices) {
        display: none;
    }
}

.-mobile-only {
    display: none !important;

    @include breakpointMax($smaller-devices) {
        display: block !important;
    }
}

.-colored {
    color: $color-main;
}

.-footnote {
    font-style: italic;
    @include font-size(1.4);
}

/* ==========================================================================
   Button
   ========================================================================== */
.button {
    @include button($color-main, $white, $color-link-hover, $white);
    //line-height: 18px;
    // @include breakpointMax($mobile) {
    //   @include font-size(1.6);
    //   padding: 12px 20px;
    //   line-height: 16px;
    // }
}

.cta-group {
    width: 100%;

    .cta-arrow {
        display: block;
        //color: $color-main;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.36px;
        text-transform: uppercase;
        position: relative;

        border-bottom: 1px solid $color-main;
        padding-bottom: 24px;
        margin-bottom: 24px;


        &:nth-child(2) {
            padding-bottom: 0;
        }

        &:after {
            content: "";
            background: url(assets/img/icon-arrow.svg) no-repeat center center;
            position: absolute;
            width: 14px;
            height: 14px;
            right: 0;
            top: 0;
        }

        &:last-child {
            border-bottom: 0px;
            margin-bottom: 0px;
        }

        @include breakpointMax($smaller-devices) {
            font-size: 16px;
            line-height: normal;
            letter-spacing: -0.32px;
        }
    }
}



.--leaf {
    border-radius: 0px 12.5%;
}

@include breakpointMin($smaller-devices-up) {
    .grid-item:hover  .button {
        color: #fff;
        background-color: #142a74;
        border: 1px solid #142a74;
        text-decoration: none;
    }
}

.no-margin {
    margin-bottom: 0;
}

.--invisible {
    opacity: 0.35;
    pointer-events: none;
}