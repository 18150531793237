.site-header {
    position: sticky;
    top: 0;
    z-index: 2000;
    @include font-size(1.8);
    line-height: normal;
    font-family: 'Marshalls-Sans-Regular';
    background-color: $color-bg-offwhite;
    overflow: hidden;

    @include breakpointMax(1200px) {
        overflow: visible;
    }


    &:not([data-page="grants"]) {
        .site-header__menu-ul-buttons {

            li {
                display: none;
            }
        }
    }

    .menu__toggle {
        background-color: $color-bg-offwhite;
    }

    .ctn-main {
        padding: 0;

        .site-header__ctn {
            display: flex;
            max-width: $container-width-with-padding;
            justify-content: space-between;
            align-items: center;
            padding: 45px 40px 24px 43px;

            @include breakpointMax(1200px) {
                padding: 20px;
            }
        }
    }

    .site-header__left {
        display: flex;
        justify-content: left;
        align-items: center;
    }

    .site-header__logo {
        width: 185px;
        margin-right: 80px;
        cursor: pointer;

        @include breakpointMax(1200px) {
            padding: 0;
            margin: 0;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    .site-header__menu-ul {
        display: flex;
        flex-wrap: nowrap;
        list-style: none;
        column-gap: 60px;

        li {
            color: $color-main;
            display: inline-block;
            margin: 0;

            >a {
                text-decoration: none;
                cursor: pointer;
                position: relative;
            }

            &:hover a:after,
            &.active a:after {
                content: "";
                background: $color-main;
                position: absolute;
                top: -64px;
                left: 0;
                height: 12px;
                width: 100%;

                @include breakpointMax(1200px) {
                    display: none;
                }
            }
        }

        @include breakpointMax(1200px) {
            display: none;
            flex-direction: column;
            row-gap: 38px;
            padding: 20px;
            position: absolute;
            top: 95px;
            left: 0;
            width: 100%;
            background-color: $color-sandy-gray;
        }
    }

    .site-header__menu-ul.open {
        display: flex;
        z-index: 100;
    }

    .site-header__right {

        display: flex;
        align-items: center;

        p {
            text-align: right;
            font-size: 15px;
            line-height: 110%;
            /* 16.5px */
            letter-spacing: -0.3px;

            width: 180px;
            margin: 0;
        }

        .menu__toggle {
            display: none;
            border: none;
            padding: 10px;
            color: $color-main;
            cursor: pointer;

            .fa:before {
                font-size: 25px;
            }

            @include breakpointMax(1200px) {
                display: block;
            }
        }

        .menu__toggle.is-visible {
            display: block;
        }

        .js-open.is-visible,
        .js-close {
            display: none;
        }

        .js-close.is-visible {
            display: block;
        }

        .menu__item {
            margin-left: 35px;
            position: relative;

            &:before {
                content: "";
                background: url(assets/img/mgssc-banner.svg) no-repeat center center;
                position: absolute;
                width: 1749.61px;
                height: 21px;
                top: 8px;
                left: -797px;
                opacity: 0;
                transition: opacity 0.5s ease-in-out;

                @include breakpointMax(1200px) {
                    content: "";
                    opacity: 0.5;
                    transform: translateX(470px) !important;
                    top: 10px;
                }
            }

            &:first-child:before {
                transform: rotate(-15deg);

                @include breakpointMax(1200px) {
                    content: "";
                    opacity: 0.5;
                    transform: translateX(250px) !important;
                    top: 10px;
                }
            }

            &:nth-child(2):before {
                background-image: url(assets/img/mgsap-banner.svg);
                transform: rotate(15deg);
            }

            &:hover {
                &:before {
                    opacity: 1;
                }

                @include breakpointMax(1200px) {
                    &:before {
                        opacity: 1;
                    }
                }
            }

            .button {
                z-index: 2;
                min-width: 152px;
                text-align: center;
            }
        }
    }

    .site-header__menu-ul-buttons {
        display: flex;
        flex-wrap: nowrap;
        list-style: none;

        @include breakpointMax(1200px) {
            display: none;
        }
    }

    .site-header__menu-ul-buttons.open {
        display: flex;
        z-index: 100;

        @include breakpointMax(1200px) {
            flex-direction: column;
            row-gap: 20px;
            padding: 30px 0 20px;
            position: absolute;
            top: 260px;
            left: 0;
            width: 100%;
            background-color: $color-sandy-gray;

            .menu__item:first-child {
                margin-left: 20px;
            }

            .menu__item:nth-child(2) {
                align-self: flex-start;
                margin-left: 20px;
            }
        }
    }
}