// MARSHALLS SANS FONTS

@font-face {
    font-family: 'Marshalls-Sans-Light';
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light.ttf");
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Sans-Light-Italic';
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light-Italic.ttf");
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Light-Italic.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Sans-Regular';
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular.ttf");
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Sans-Regular-Italic';
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular-Italic.ttf");
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Regular-Italic.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Sans-Medium';
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium.ttf");
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Sans-Medium-Italic';
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium-Italic.ttf");
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Medium-Italic.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Sans-SemiBold';
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold.ttf");
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Sans-SemiBold-Italic';
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold-Italic.ttf");
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-SemiBold-Italic.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Sans-Bold';
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold.ttf");
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serifs/Marshalls-Sans-Bold.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Sans-Bold-Italic';
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold-Italic.ttf");
    src: url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Sans/Marshalls-Sans-Bold-Italic.woff2") format("woff2");
}

//MARSHALS SERIF FONTS

@font-face {
    font-family: 'Marshalls-Serif-Light';
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light.ttf");
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Serif-Light-Italic';
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light-Italic.ttf");
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Light-Italic.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Serif-Regular';
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular.ttf");
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Serif-Regular-Italic';
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular-Italic.ttf");
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Regular-Italic.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Serif-Medium';
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium.ttf");
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Serif-Medium-Italic';
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium-Italic.ttf");
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Medium-Italic.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Serif-Demi';
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi.ttf");
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Serif-Demi-Italic';
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi-Italic.ttf");
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Demi-Italic.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Serif-Bold';
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold.ttf");
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serifs/Marshalls-Serif-Bold.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold.woff2") format("woff2");
}

@font-face {
    font-family: 'Marshalls-Serif-Bold-Italic';
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold-Italic.ttf");
    src: url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold-Italic.ttf") format("truetype"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold-Italic.otf") format("otf"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold-Italic.woff") format("woff"), url("../../assets/webfonts/Marshalls-Serif/Marshalls-Serif-Bold-Italic.woff2") format("woff2");
}