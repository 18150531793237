.content-page {
	margin-bottom: 80px;

	.ctn-main > * {
		margin-left: 250px;

		@include breakpointMax(768px) {
			margin-left: 32px;
		}


		&:is(h2) {
			margin-left: 0;
		}
	}

	h2 {
		font-family: "Marshalls-Serif-Demi-Italic";
		font-size: 86px;
		font-style: italic;
		font-weight: 700;
		line-height: 100%; /* 86px */
		letter-spacing: -4.3px;
		margin: 45px 0 0;

		@include breakpointMax(768px) {
			font-size: 44px;
			letter-spacing: -2.2px;
			line-height: normal;
			margin: 40px 0 40px;
		}

	}

	h3 {
		font-family: "Marshalls-Serif-Demi-Italic";
		font-size: 52px;
		font-style: italic;
		font-weight: 700;
		letter-spacing: -2.6px;	
		margin-top: 80px;
		margin-bottom: 40px;

		@include breakpointMax(768px) {
			font-size: 36px;
			line-height: normal;
			letter-spacing: -1.8px;
		}
	}

	.accordion-wrapper {

		margin-left: 250px;

		@include breakpointMax(768px) {
			margin-left: 18px;
		}

		ul {
			list-style: none;
			margin: 0;
		}
	}
	
	.container-btn {
		margin-bottom: 40px;
	}

	.accordion-title {
		font-size: 28px;
        line-height: normal;
		letter-spacing: -1.4px;
		position: relative;
		padding-left: 57px;
		padding-bottom: 18px;
		cursor: pointer;

		@include breakpointMax(768px) {
			font-size: 20px;
			line-height: normal;
			letter-spacing: -1px;
		}


		&:before {
			content: "";
			display: block;

			width: 37px;
			height: 37px;
			position: absolute;
			left: 0;
			top: -6.5px;
            cursor: pointer;
			
			border-radius: 50%;
			border: 2px solid var(--marshalls-brand-color-palette-rgb-marshalls-core-blue-rgb, #0024CC);
			background: var(--marshalls-brand-color-palette-rgb-marshalls-soft-purple-rgb, #E1CFE6);

			@include breakpointMax(768px) {
				transform: scale(0.75);
			}
		}

		&:after {
			content: "+";
			font-size: 32px;
			text-align: center;
			display: block;
            cursor: pointer;

			width: 24px;
			height: 24px;
			line-height: 27px;
			position: absolute;
			left: 8px;
			top: 0px;
			overflow: hidden;
			transition: all 0.25s ease-out;

            @include breakpointMax(768px) {
                top: 0px;
                transform: scale(0.75);
            }
		}
	}

	.expanded .accordion-title:after {
		content: "-";
	}

	.content {
		color: var(--marshalls-brand-color-palette-rgb-marshalls-navy-rgb, #142A74);
		font-size: 18px;
		line-height: 110%; /* 19.8px */
		letter-spacing: -0.36px;
		padding-left: 57px;
		display: none;

		a {
			text-decoration: underline;
		}

        a:hover {
            //text-decoration: none;
        }

        ul li {
        	list-style: disc;
            font-size: 18px;

            @include breakpointMax($mobile) {
            	font-size: 15px;
            }
        }

        ol li {
        	list-style: number;
        }

        ol:p:last-child, ul:p:last-child, p:last-child {
        	margin-bottom: 0;
        }
	}
}