.cookie-bar {
    color: #fff;
    background-color: $color-main;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20px 40px;
    font-size: 16px;
    line-height: 120%;

    @include breakpointMax($smaller-devices) {
        padding: 20px;
    }

    .ctn-main {
        display: flex;
        justify-content: space-between;

        @include breakpointMax($smaller-devices) {
            flex-direction: column;
            padding: 0;
        }
    }

    p {
        margin: 0;
    }

    a {
        color: white;
    }

    .button-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 40px;
        gap: 10px;

        @include breakpointMax($containerLargeBreakpoint) {
            flex-direction: column;
        }

        @include breakpointMax($smaller-devices) {
            flex-direction: row;
            margin: 20px 0;
        }
    }

    .button {
        background-color: white;
        color: #0024cc;
        white-space: nowrap;
        text-align: center;
        font-size: 14px;
        padding: 12px 18px;
    }
}


body .--cookie-optout,
body .--cookie-all {
    display: none;
}

body.--cookie-all .--cookie-all {
    display: block;
}

body.--cookie-optout .--cookie-optout {
    display: block;
}
