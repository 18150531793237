.mission-cpartners {
    background-color: $color-bg-offwhite;

    .ctn-main {
        background-color: $color-bg-offwhite;
        padding: 80px;

        @include breakpointMax($smaller-devices) {
            padding: 40px;
        }

        .cpartners-wrapper {
            background-color: $color-bg-offwhite;
        }
    }

    .title {
        margin-bottom: 48px;
        h2 {
            line-height: 46px;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;

        @include breakpointMax($smaller-devices) {
            display: block;
        }

        .left-section {
            flex: 1 0 33%;
            align-self: flex-start;

            @include breakpointMax($smaller-devices) {
                margin-bottom: 40px;
            }

            p {
                @include font-size(2.2);
                line-height: 22px;
                letter-spacing: -1.1px;
                max-width: 254px;
                margin: 0;

                @include breakpointMax($smaller-devices) {
                    @include font-size(2.0);
                    line-height: 20px;
                    letter-spacing: -1px;
                    max-width: 100%;
                }
            }
        }


        .right-section {
            flex: 1 0 66%;
            display: flex;
            column-gap: 40px;

            @include breakpointMax($smaller-devices) {
                display: block;
                padding: 20px;
                row-gap: 40px;
            }

            .col-1,
            .col-2,
            .col-3 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                @include breakpointMax($smaller-devices) {
                    row-gap: 12px;
                    margin-bottom: 80px;
                }

                .top {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 20px;

                    div {
                        display: flex;
                        align-items: center;
                        height: 80px;

                        @include breakpointMax($smaller-devices) {
                            height: 60px;
                        }
                    }

                }
            }

            img#girls-inc {
                width: 100px;
                height: 78px;

                @include breakpointMax($mobile-small) {
                    width: 80px;
                    height: 63px;
                }
            }

            img#mentor {
                width: 194px;
                height: 51px;

                @include breakpointMax($mobile-small) {
                    width: 155px;
                    height: 40px;
                }
            }

            img#savvy-ladies {
                width: 187px;
                height: 28px;

                @include breakpointMax($mobile-small) {
                    width: 150px;
                    height: 22px;
                }
            }

            h6 {
                color: $color-darker-blue;
                @include font-size(1.3);
                line-height: 13px;
                letter-spacing: 1.3px;
                text-transform: uppercase;
                font-family: 'Marshalls-Sans-Medium';
            }

            p {
                margin: 0;
                padding: 20px 0;
                color: $color-darker-blue;
                border-top: 2px solid $color-main;
            }
        }
    }
}