.mission-access {

    .ctn-main {
        background-color: $white;
        padding: 80px;

        @include breakpointMax($smaller-devices) {
            padding: 40px;
        }

        .access-wrapper {
            background-color: $white;
            display: flex;
            justify-content: space-around;

            @include breakpointMax($smaller-devices) {
                flex-direction: column;
                row-gap: 28px;
            }

            p.deep-text {
                color: $color-darker-blue;
            }
        }

        .left-image {
            flex: 1 0 25%;
            place-self: flex-start;

            img {
                width: 134px;
                height: 179px;

                @include breakpointMin($mobile-xsmall-up) {
                    width: 260px;
                    height: 347px;
                }
                @include breakpointMin($mobile-small-up) {
                    width: 342px;
                    height: 470px;
                }   
            }

            @include breakpointMax($smaller-devices) {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                gap: 12px;

                h2 {
                    @include font-size(3.6);
                    line-height: normal;
                    letter-spacing: -1.8px;
                }
            }
        }

        .mid-section {
            flex: 1 0 35%;
            align-self: flex-start;
            padding: 0 20px;
            max-width: 387px;

            @include breakpointMax($smaller-devices) {
                padding: 0;
                margin: 0 auto;
                max-width: 100%;
            }

            // .content {
            //     max-width: 387px;

            //     @include breakpointMax($smaller-devices) {
            //         padding: 0;
            //         margin: 0 auto;
            //     }
            // }

            h2 {
                margin-bottom: 40px;
                line-height: 46px;
            }
        }

        .right-section {
            flex: 1 0 30%;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-left: 2px solid $color-main;

            @include breakpointMax($smaller-devices) {
                border-left: none;
                align-items: flex-start;
            }

            .inner {
                margin: 20px 80px;

                @include breakpointMax($smaller-devices) {
                    margin: 0px;
                }
            }

            h2 {
                @include breakpointMax($smaller-devices) {
                    @include font-size(4.4);
                    line-height: 44px;
                    letter-spacing: -2.2px;
                }
            }

            #chart {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                column-gap: 10px;

                p {
                    margin: 0;
                }
            }

            .mid {
                padding-top: 20px;
                border-bottom: 2px solid $color-main;

                @include breakpointMax($smaller-devices) {
                    padding: 40px 0 20px;
                }
            }

            .top {
                border-bottom: 2px solid $color-main;

                @include breakpointMax($smaller-devices) {
                    padding: 40px 0 20px;
                    border-top: 2px solid $color-main;
                }
            }

            .bottom {
                padding-top: 20px;

                @include breakpointMax($smaller-devices) {
                    padding-top: 40px;
                }
            }
        }
    }

}