body.grants {

	background-color: #f4f1ee;

	.site-header .site-header__logo {
		width: auto;

		img {
			width: 300px;
		    display: block;
		    height: 75px;
		    object-fit: contain;
		    object-position: left;
		}
	}
	
	.site-header .button {
		background-color: #142A74;
	}

    .site-header .site-header__right {
        .menu__item::before {
            transform: rotate(15deg) !important;

            @include breakpointMax(1200px) {
                left: -300px;
                top: 10px;
                transform: translateX(-250px) !important;
            }
        }
    }


	@include breakpointMax(1200px) {
		.site-header__right p {
			display: none;

			&:has(+ .open) {
				position: absolute;
		        top: 120px;
		        width: 100%;
		        z-index: 101;
		        display: block;
		        left: 0;
		        text-align: center;
			}
		}

		.site-header .site-header__menu-ul-buttons.open {
			top: 100px;
			padding-top: 60px;
			text-align: center;

			li:first-child {
				margin-left: 0;
			}
		}
	}


	.hero-section {

		.top-text p {
			color: var(--Marshalls-Brand-Color-Palette-Marshalls-Off-White---New, #F4F1EE);
			font-size: 28px;
			letter-spacing: -1.4px;
			transform: translateY(25px);

			@include breakpointMax(1400px) {
				font-size: 20px;
				letter-spacing: -1px;
				transform: translateY(12px);
			}

			@include breakpointMax($smaller-devices) {
				font-size: 16px;
				line-height: 110%; /* 17.6px */
				letter-spacing: -0.32px;
				transform: translateY(8px);
			}
		}

		h1.sub-hero {
			color: var(--marshalls-brand-color-palette-rgb-marshalls-soft-purple-rgb, #E1CFE6);
			font-family: "Marshalls-Serif-Bold-Italic";
			font-size: 110px;
			font-style: italic;
			font-weight: 600;
			line-height: normal;
			letter-spacing: -5.5px;

			@include breakpointMax(1400px) {
				font-size: 76px;
				letter-spacing: -4.2px;
				white-space: nowrap;
			}

			@include breakpointMax($smaller-devices) {
				font-size: 38px;
				letter-spacing: -2.2px;
			}
		}

		h1.sub-hero strong {
			color: var(--marshalls-brand-color-palette-rgb-marshalls-soft-purple-rgb, #E1CFE6);
			font-family: "Marshalls-Sans-Light";
			font-size: 52px;
			letter-spacing: -2.6px;
			font-style: normal;
			font-weight: 400;
			display: block;

			@include breakpointMax(1400px) {
				font-size: 40px;
				letter-spacing: -2.2px;
			}

			@include breakpointMax($smaller-devices) {
				font-size: 28px;
				letter-spacing: -1.4px;
			}
		}
	}

    .desc-section {
        p.desc {
            line-height: 34.5px;

            a {
		        text-decoration: underline;
		    }
        }

        a {
            text-decoration-skip-ink: none;
            text-decoration-thickness: 2px !important;
        }
    }

    .hero-section .ctn-main .hero-right p.sub-hero {
		@include breakpointMax(1400px) {
			font-size: 22px;
			line-height: 110%;
			letter-spacing: -0.32px;
	    }

		@include breakpointMax($smaller-devices) {
			font-size: 16px;
			line-height: 110%; /* 17.6px */
			letter-spacing: -0.32px;
			margin: 24px 24px 40px;
	    }
	}

	.ctn-main .desc-wrapper p {
		max-width: 764px;
	}

	.site-content p.disclaim {
    	font-size: 14px;
    	line-height: 18px;
	}
}


.grants-instructions {
	height: auto;
	min-height: auto;
    margin-bottom: 40px;

	@include breakpointMax($smaller-devices) {
		height: auto;
	}

    a {
        text-decoration-skip-ink: none;
        text-decoration-thickness: 1px !important;
    }

	.toggle {
		@include breakpointMax($smaller-devices) {
			display: none;
		}
	}

    &.--fixed-container {
        display: none;
    }

	&.--sticky {
		& > div {
			position:fixed;
			width: 100%;
			top: 129px;
			left: 0;
			transform: translateY(-100%);

			@include breakpointMax($smaller-devices) {
				transform: translateY(calc(-100% + 54px));
			}
			
			.ctn-main {
				padding-left: 0;
				padding-right: 0;
			}

			.chapter-nav {
				border-radius: 0 0 12px 12px;
				box-shadow: 0px 0px 20px 0px #00000040;
				transform: translateY(54px);
			}

			.chapter-contents {
				border-radius: 0;
				opacity: 0;

				@include breakpointMax($smaller-devices) {
					opacity: 1;
				}
				//transition: background-color .2s .4s ease-out;
			}

			@include breakpointMax(1200px) {
				top: 100px;
			}

			&.--once, &.--open {
				transition: transform .4s ease-out;
			}

			&.--open {
				transform: translateY(0px);

				.--on {
					display: block;
				}

				.--off {
					display: none;
				}

				.toggle:after {
					content: "-";
				}

				.chapter-nav {
					border-radius: 0 0 0 12px;
					transform: translateY(0px);

					@include breakpointMax($smaller-devices) {
						border-radius: 0 0 0 0;
					}

					.toggle {
						@include breakpointMax($smaller-devices) {
						    position: fixed;
						    transform: translateX(-24px);
						    background: #e6dfda;
					        width: calc(100% + 24px);
					        padding-top: 17px;
					        top: 0;

							&:before { 
								top: 4px;
							}

							&:after { 
								top: 12px;
							}

							&.mobile {
								display: block;
							}
						}
					}
				}

				.chapter-contents {
					border-radius: 0 0 12px 0;
					background-color: white;
					opacity: 1;
					//transition: background-color .2s ease-out;

					@include breakpointMax($smaller-devices) {
						border-radius: 0 0 12px 12px;
					}

					.toggle {
						display: none;
					}
				}
				.ctn-main {
					border-radius: 0 0 12px 12px;
					box-shadow: 0px 0px 20px 0px #00000040;
					max-height: calc(100dvh - 100px);
    				overflow: hidden;

    				@include breakpointMax($smaller-devices) {
						overflow: scroll;
					}
				}
			}
		}

		.toggle {
			display: flex;
    		align-items: center;
		}

		.chapter-contents {
			background-color: $white;

			@include breakpointMax($smaller-devices) {
				padding-bottom: 60px;
				border-radius: 0 0 12px 12px;
			}
		}

		.ctn-main {
			@include breakpointMax($smaller-devices) {
				padding-left: 0;
			}
		}

		.chapter-nav .toggle {
			display: flex;

			@include breakpointMax($smaller-devices) {
				display: none;
			}
		}

		.chapter-contents .toggle {
			display: none;

			@include breakpointMax($smaller-devices) {
				display: flex;
				background-color: #f4f1ee;
			}
		}
	}


	.toggle {
		display: none;
		position: absolute;
    	bottom: 0;
    	height: 55px;
    	line-height: 55px;
    	padding-left: 40px;
    	cursor: pointer;
    	white-space: nowrap;

    	@include breakpointMax($smaller-devices) {
			left: 0px;
			width: 100%;
			padding-left: 65px;
		}

    	&:before {
			content: "";
			display: block;

			width: 37px;
			height: 37px;
			position: absolute;
			left: -70px;
			top: 6px;
            cursor: pointer;
			
			border-radius: 50%;
			border: 2px solid var(--marshalls-brand-color-palette-rgb-marshalls-core-blue-rgb, #0024CC);
			background: var(--marshalls-brand-color-palette-rgb-marshalls-soft-purple-rgb, #E1CFE6);

			@include breakpointMax(768px) {
				transform: scale(0.75);
			}

			@include breakpointMax($smaller-devices) {
				left: calc(50% - 155px);
			}
		}

		&:after {
			content: "+";
			font-size: 32px;
			text-align: center;
			display: block;
            cursor: pointer;

            position: absolute;
			width: 24px;

    		left: -61px;

    		height: 24px;
			line-height: 24px;
			top: 14px;

			overflow: hidden;
			transition: all 0.25s ease-out;

            @include breakpointMax(768px) {
                top: -2px;
                transform: scale(0.75);
            }

            @include breakpointMax($smaller-devices) {
				left: calc(50% - 146px);
				top: 14px;
			}
		}

		span {
			color: var(--marshalls-brand-color-palette-rgb-marshalls-core-blue-rgb, #0024CC);
			font-family: "Marshalls-Sans-SemiBold";
			font-weight: 600;
			text-align: center;
			font-size: 18px;
			line-height: normal;
			letter-spacing: -0.36px;
			text-transform: uppercase;

			@include breakpointMax($smaller-devices) {
				padding-left: 6px;
				width: 100%;
			}
		}

		.--on {
			display: none;
		}

		.--off {

		}
	}

	.ctn-main {
	    align-items: stretch;
	    height: 450px;
	    display: flex;
	    align-content: stretch;
	    flex-direction: row;
	    flex-wrap: nowrap;

	    @include breakpointMax($smaller-devices) {
			display: block;
			height: auto;
		}
	}

	.chapter-nav {

		background-color: #E6DFDA;
		padding: 54px 108px 54px 92px;
		min-width: 464px;
        border-radius: 12px 0 0 12px;
        position: relative;

		@include breakpointMax($smaller-devices) {
			padding: 60px 20px 20px 40px;
			min-width: auto;
			border-radius: 12px 12px 0 0;
		}

		ul {
			list-style: none;
		}

		.toggle { 
			display: none;
		}

		.chapter-nav__item {

			white-space: nowrap;
			margin: 0;

			a {
				color: var(--marshalls-brand-color-palette-rgb-marshalls-navy-rgb, #142A74);
				font-size: 28px;
				line-height: normal;
				letter-spacing: -1.4px;
				cursor: pointer;
				display: block;
				margin-bottom: 16px;
			}

			&.active a {
				color: $color-main;
				font-family: "Marshalls-Sans-SemiBold";
				font-weight: 600;
				position: relative;

				&:before {
                    content: "";
                    background: url(assets/img/icon-softblue-arrow.svg) no-repeat center center;
                    background-size: 100% 100%;
                    position: absolute;
                    width: 42px;
                    height: 11.5px;
                    left: -54px;
                    top: 10px;

                    @include breakpointMax($smaller-devices) {
                    	width: 20px;
                    	left: -30px;
                    }
				}
			}

            &:not(.active) a:hover {
                text-decoration: underline;
            }
		}
	}

	.chapter-contents {

		background-color: white;
		padding: 50px 30px 50px 126px;
		position: relative;
		//transition: background-color .2s ease-out;
		border-radius: 0 12px 12px 0;

		@include breakpointMax($smaller-devices) {
			padding: 20px 20px 20px 30px;
			border-radius: 0 0 12px 12px;
		}

		& > div {
			display: none;

			&.active {
				display: block;
			}

			p {
				color: var(--Black, #000);
				/* Desktop Sans/P */
				// font-size: 18px;
				// line-height: 110%; /* 19.8px */
				// letter-spacing: -0.36px;
				margin-top: 0;

                a:not(.button):hover {
                    color: $color-main;
                }
			}

			a {
				font-family: "Marshalls-Sans-Regular";
                color: $black;
				text-decoration: underline;
			}

            a.button.smooth {
                color: $white;
                text-decoration: none;
            }

			ul {
				margin-bottom: 1.5em;
			}

			li {
				color: var(--Black, #000);
                @include font-size(1.8);
                line-height: normal;

                @include breakpointMax($mobile) {
                    @include font-size(1.5);
                    line-height: normal;
                }
			}

			h3 {
				color: var(--marshalls-brand-color-palette-rgb-marshalls-core-blue-rgb, #0024CC);
				font-family: "Marshalls-Serif-Bold-Italic";
				font-size: 40px;
				font-style: italic;
				font-weight: 700;
				line-height: normal;
				letter-spacing: -2px;
				margin-bottom: 40px;
			}

            span {
                color: $color-darker-blue;
                font-family: "Marshalls-Sans-SemiBold";
				font-size: 18px;
				font-weight: 600;
				letter-spacing: -0.36px;
				text-transform: uppercase;
            }

			.chapter-link {
				color: $color-main;
				font-family: "Marshalls-Sans-SemiBold";
				font-size: 18px;
				font-weight: 600;
				letter-spacing: -0.36px;
				text-transform: uppercase;
				// position: absolute;
    			// bottom: 65px;

                &:hover {
                    color: $color-darker-blue;
                }

    			@include breakpointMax($smaller-devices) {
					position: static;
				}
			}
		}

		.--scrollable {
			padding-right: 170px;
			overflow: auto;
			overflow-x: hidden;
			height: 236px;
            margin-bottom: 34px;

			@include breakpointMax($smaller-devices) {
				overflow: visible;
				height: auto;
				padding-right: 0;
			}

			&::-webkit-scrollbar {
			  width: 12px;
			}
			 
			&::-webkit-scrollbar-track {
			  background-color: #CEE0F2;
			  border-radius: 6px;
			}
			 
			&::-webkit-scrollbar-thumb {
			  background-color: #0024CC;
			  border-radius: 6px;
			  cursor: pointer;
			}
		}
	}
}

.grants-form {
	margin: 0 90px;

	@include breakpointMax($smaller-devices) {
		margin: 0;
	}
	
	.intro {
		margin: 100px auto 90px; 
		width: 760px;

		@include breakpointMax($smaller-devices) {
			width: auto;
		}

		h2 {
			color: var(--marshalls-brand-color-palette-rgb-marshalls-core-blue-rgb, #0024CC);
			text-align: center;
			/* Desktop Serif Bold/H1 */
			font-family: "Marshalls Serif";
			font-size: 86px;
			font-style: italic;
			font-weight: 700;
			line-height: 100%; /* 86px */
			letter-spacing: -4.3px;

			@include breakpointMax($smaller-devices) {
				font-size: 36px;
				letter-spacing: -1.8px;
			}
		}

		p {
			color: var(--Black, #000);

			text-align: center;
			/* Desktop Sans/P */
			font-family: "Marshalls-Sans-Light";
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 110%; /* 19.8px */
			letter-spacing: -0.36px;
		}
	}

	.dja {
        display: none;
		padding: 35px 0;

		border-radius: 12px;
		background: white;
		margin-bottom: 80px;
	}

	#sweepsiframe {
	    width: 100%; /*REQUIRED – DO NOT ALTER*/
	    height:100%; /*REQUIRED – DO NOT ALTER*/
	    overflow:hidden; /*REQUIRED – DO NOT ALTER*/
	    border:0; 
	    /* More styles can be added as needed based on site design */
    }
}