.two-col-text-image {
    background-color: $color-main;

    .ctn-main {
        background: $color-main;

        &:hover {
            .--left-section {
                border-radius: 0 0 0 80px;
            }

            .--right-section img {
                border-radius: 0 80px 0 0;
            }
        }
    }

    .--grid-container {
        display: flex;
        padding: 40px;
        align-items: stretch;

        .mobile-img {
            display: none;
        }

        .desktop-img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include breakpointMax($smaller-devices) {
            display: block;
            padding: 20px;

            .mobile-img {
                display: block;
                width: 100%;
                height: 100%;
            }

            .desktop-img {
                display: none;
            }
        }
    }

    h6 {
        color: $color-darker-blue;
        margin-bottom: 20px;
    }

    .grid-item {
        max-width: 50%;
        position: relative;

        .cta-box {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 10;
        }

        @include breakpointMax($smaller-devices) {
            max-width: none;
        }
    }

    .--left-section,
    .--right-section img {
        transition: border-radius 0.5s ease-out;
    }

    .--left-section {
        display: flex;
        padding: 80px 40px 40px 40px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
        background-color: $color-light-purple;

        @include breakpointMax($smaller-devices) {
            padding: 30px;
        }

        .content-top {
            max-width: 558px;
        }
    }

}