.hero-banner {
    color: $color-main;
    background-color: $color-main;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include breakpointMin($smaller-devices-up) {
            height: auto;
        }
    }


    .ctn-main.--grid-container {
        display: grid;
        padding: 0;
        grid-template-areas: "title smiling-woman"
                            "title explore";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        background-color: $color-main;

        @include breakpointMax($smaller-devices) {
            display: block;
        }

        @include breakpointMin($smaller-devices-up) {
            &:hover {
                .--left-section {
                    border-radius: 0 0 0 80px;
                }

                .--right-section img {
                    border-radius: 0 80px 0 0;
                }
            }
        }

        h1 {
            margin-bottom: 5px;
        }

        h6 {
            margin-bottom: 12px;
        }

        .grid-item {
            position: relative;

            .content-top {
                max-width: 460px;

                @include breakpointMax($smaller-devices) {
                    max-width: 100%;
                }
            }

            .cta-box {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 10;
            }

            &.--title {
                grid-area: title;
                background-color: $white;
                border-radius: 0 30%;
                padding: 120px 76px 40px 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @include breakpointMax($smaller-devices) {
                    padding: 80px 40px 40px;
                    max-width: 100%;    
                }

                h1 {
                    max-width: 600px;

                    @include breakpointMax($smaller-devices) {
                        max-width: 100%;
                    }
                }

                .button {
                    align-self: flex-end;
                }
            }

            &.--smiling-woman {
                height: 343px;

                @include breakpointMax($smaller-devices) {
                    height: auto;
                }
            }

            &.--explore {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 40px;
                background-color: $color-sandy-gray;

                h2 {
                    margin-bottom: 23px;
                }

                h6 {
                    color: $color-darker-blue;
                }

                .button {
                    align-self: flex-end;
                }

                @include breakpointMax($smaller-devices) {
                    .button {
                        align-self: flex-start;
                        margin-top: 48px;
                    }
                }
            }

            // &.--left-section {
            //     grid-area: left-section;
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: space-between;
            //     padding: 80px 40px 40px;
            //     margin: 40px 0 40px 40px;
            //     height: 442px;

            //     &.color-lightpurple {
            //         background-color: $color-light-purple;
            //     }

            //     &.false {
            //         padding: 0px;
            //     }

            //     h6 {
            //         &.color-darker-blue {
            //             color: $color-darker-blue;
            //         }
            //     }

            //     h2 {
            //         &.color-main {
            //             color: $color-main;
            //         }
            //     }

            //     .button {
            //         max-width: fit-content;
            //     }

            //     @include breakpointMax($smaller-devices) {
            //         margin: 15px 15px 0 15px;
            //         padding: 20px;
            //         height: auto;

            //         .button {
            //             margin-top: 48px;
            //         }
            //     }
            // }

            // &.--right-section {
            //     grid-area: right-section;
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: space-between;
            //     margin: 40px 40px 40px 0;
            //     height: 442px;

            //     img {
            //         min-height: 442px;
            //     }

            //     &.true {
            //         padding: 40px
            //     }

            //     &.false {
            //         padding: 0px;
            //     }


            //     .button {
            //         max-width: fit-content;
            //     }

            //     @include breakpointMax($smaller-devices) {
            //         margin: 0 15px 0 15px;
            //         height: auto;

            //         .button {
            //             margin-top: 48px;
            //         }
            //     }
            // }

        }

        // .--left-section,
        // .--right-section img {
        //     transition: border-radius 0.5s ease-out;
        // }

    }

}