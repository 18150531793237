.featured-event {
    background-color: $color-bg-offwhite;
    .ctn-main {
        background: $color-main;
    }

    .--grid-container {
        display: flex;
        padding: 40px;
        background-color: $color-sandy-gray;
        align-items: stretch;
        transition: background-color 0.5s ease-out;

        &:hover {
            .--left-section {
                background-color: $color-light-blue;
            }

            .event-card {
                background-color: $color-darker-blue;
            }
        }

        @include breakpointMax($smaller-devices) {
            display: block;
            padding: 0 20px;
            width: auto;
        }
    }

    .grid-item {
        max-width: 50%;

        @include breakpointMax($smaller-devices) {
            max-width: 100%;
        }
    }

    .--left-section {
        display: flex;
        padding: 40px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
        background-color: $color-light-purple;

        @include breakpointMax($smaller-devices) {
            padding: 20px;
        }

        img {
            border-radius: 318px 318px 0 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .event-card {

        position: relative;

        &:after {
            content: "";
            background: url(assets/img/gssc-badge.gif) no-repeat center center;
            background-size: 100% 100%;
            position: absolute;
            width: 145px;
            height: 145px;
            right: 20px;
            top: 20px;

            @include breakpointMax($mid-devices) {
                width: 74px;
                height: 74px;
            }
        }

        background-color: $color-main;
        display: flex;
        min-height: 506px;
        padding: 80px 40px 40px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 40px;
        //margin-left: 40px;

        @include breakpointMax($smaller-devices) {
            padding: 20px;
            margin-left: 0px;
            gap: 18px;
        }

        h1 {
            color: $color-light-purple;
            margin-bottom: 60px;

            @include breakpointMax($smaller-devices) {
                margin-bottom: 0px;
            }
        }

        h5 {
            color: $color-light-purple;
        }

        h3 {
            color: $white;
            font-family: 'Marshalls-Sans-Light';
            margin-bottom: 30px;

            @include breakpointMax($smaller-devices) {
                margin-bottom: 18px;
                @include font-size(2.4);
                line-height: normal;
                letter-spacing: -1.2px;
            }

            @include breakpointMax($mobile-xxxsmall) {
               max-width: 200px;
            }
        }

        h4 {
            @include breakpointMax($smaller-devices) {
                @include font-size(1.6);
                line-height: normal;
                letter-spacing: -0.2px;
            }
        }

        .card-header {
            
            @include breakpointMax($smaller-devices) {
                max-width: 70%;
            }

            .tag {
                position: relative;
                padding-left: 25px;
                margin-bottom: 30px;

                &:before {
                    content: "";
                    background: url(assets/img/icon-filter-arch-purple.svg) no-repeat center center;
                    position: absolute;
                    width: 16.5px;
                    height: 16.5px;
                    left: 5px;
                    bottom: 0px;

                    @include breakpointMin($mobile-small) {
                        bottom: -1px;
                    }

                    @include breakpointMin($mobile) {
                        bottom: 2px;
                    }
                }
            }
        }

        .card-body {
            border-top: 2px solid $white;
            padding-top: 10px;
            color: $white;
            font-size: 18px;
            line-height: 110%;
            /* 19.8px */
            letter-spacing: -0.36px;
            display: flex;

            @include breakpointMax($smaller-devices) {
                display: block;
            }

            h3 {
                margin-right: 80px;
            }

            p {
                margin: 0;
            }

            a { 
                color: white;
                text-decoration: underline;
            }
        }

        .card-footer {
            width: 100%;

            .cta-group {
                border-top: 2px solid white;
                padding-top: 10px;
            }

            .cta-arrow {
                color: $white;
                font-family: 'Marshalls-Sans-Medium';

                &:after {
                    background-image: url(assets/img/icon-arrow-white.svg);
                    background-size: 100% 100%;
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    div#Tock_widget_container>div.TockWidgetWrapper path {
        fill: var(--text-inverse, #fff);
    }
}