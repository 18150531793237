/* Basic HTML Elements */

* {
    @include box-sizing(border-box)
}


html,
body {
    overflow-x: hidden;
    /* disables horizontal scrolling on touche devices */
    height: 100%;
}

html {
    font-size: 62.5%;
}

body {
    background-color: $color-bg-body;
    margin: 0;
    font-family: $font-main;
    color: $color-main;
    @include font-size($font-size);
    line-height: 26px;

    @include breakpointMax($mobile) {
        overflow-x: hidden;
        @include font-size(1.4);
        line-height: 20px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    clear: both;
    margin: 0;
    font-family: $font-accent;
    font-weight: normal;
}

h1 {
    @include font-size(8.6);
    line-height: 86px;
    letter-spacing: -4.3px;

    @include breakpointMax($mobile) {
        @include font-size(4.0);
        line-height: 40px;
        letter-spacing: -2px;
    }
}


h1.sub-hero {
    @include font-size(12.0);
    line-height: 96px;
    letter-spacing: -6px;
    font-family: 'Marshalls-Sans-Light';

    @include breakpointMax($mid-devices) {
        @include font-size(8.6);
        line-height: 68px;
    }

    @include breakpointMax($mobile) {
        @include font-size(4.4);
        line-height: 44px;
        letter-spacing: -2.2px;
    }
}

h2 {
    @include font-size(5.2);
    line-height: normal;
    letter-spacing: -2.6px;

    @include breakpointMax($mobile) {
        @include font-size(3.4);
        line-height: normal;
        letter-spacing: -1.7px;
    }
}

h3 {
    @include font-size(4.0);
    line-height: normal;
    letter-spacing: -2px;

    @include breakpointMax($mobile) {
        @include font-size(3.4);
        line-height: normal;
        letter-spacing: -1.7px;
    }
}

h4 {
    @include font-size(2.4);
    line-height: 29px;

    @include breakpointMax($mobile) {
        @include font-size(2);
        line-height: 23px;
    }
}

h5 {
    @include font-size(1.8);
    line-height: 19.8px;
    letter-spacing: -0.36px;

    @include breakpointMax($mobile) {
        @include font-size(1.4);
        line-height: 14px;
        letter-spacing: -0.28px;
    }
}

h6 {
    @include font-size(1.2);
    line-height: 12px;
    letter-spacing: 1.2px;
    font-family: 'Marshalls-Sans-Medium';
}

.upper {
    text-transform: uppercase;
}

p {
    @include font-size(1.8);
    margin-bottom: 1.5em;
    line-height: 19.8px;
    letter-spacing: -0.36px;

    @include breakpointMax($mobile) {
        @include font-size(1.5);
        line-height: 16.5px;
        letter-spacing: -0.3px;
    }
}

p.sub-hero {
    @include font-size(2.8);
    line-height: 28px;
    letter-spacing: -1.4px;

    @include breakpointMax($mid-devices) {
        @include font-size(2.2);
        line-height: 22px;
    }

    @include breakpointMax($mobile) {
        @include font-size(2.0);
        line-height: 20px;
        letter-spacing: -1px;
        max-width: 226px;
    }
}

p.desc {
    @include font-size(3.2);
    line-height: normal;
    letter-spacing: -1.6px;


    @include breakpointMax($mobile) {
        @include font-size(2.4);
        line-height: normal;
        letter-spacing: -1.2px;
    }
}


strong {
    font-family: 'Marshalls-Serif-Bold-Italic';
}

b {
    font-family: $font-accent;
}

dfn,
cite,
em,
i {
    font-style: italic;
}

sup,
sub {
    @include font-size(1.2);
    height: 0;
    line-height: 0;
    position: relative;
    padding-left: 3px;
}

sup {
    top: -1rem;
}


a {
    color: $color-main;
    text-decoration: none;

    &:visited {
        color: $color-main;
    }

    &:hover,
    &:focus,
    &:active {
        color: $color-main;
        text-decoration: underline;
        outline: 0 none;
    }
}

p a {
    text-decoration: underline;
}

a {
    cursor: pointer;
}

ul,
ol {
    margin: 0;
    padding: 0;

    li {
        margin-left: 15px;
    }
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

li>ul,
li>ol {
    margin-bottom: 0;
    margin-left: 1.5em;
}

dt {
    font-family: $font-accent;
}

dd {
    margin: 0 1.5em 1.5em;
}

hr {
    background-color: $color-border;
    border: 0;
    width: 100%;
    height: 1px;
    margin: 1.5em 0;
}

img {
    height: auto;
    /* Make sure images are scaled correctly. */
    border: 0 none;

    /* IE8 */
    .ctn-img & {
        width: 100%;
    }

    @include breakpointMax($mobile) {
        max-width: 100%;
        /* Adhere to container width. */
    }
}

figure {
    margin: 0;
}

table {
    padding: 0;
    border-spacing: 0;
    border-collapse: separate;
}

::placeholder {
    font-style: italic;
}