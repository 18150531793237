.hero-section {
    background-color: $color-bg-offwhite;

    .mobile-img {
        display: none;
    }

    .desktop-img {
        display: block;
        width: 50%;
        height: 688px;
        object-fit: cover;
        object-position: left;
    }

    @include breakpointMax($smaller-devices) {
        .mobile-img {
            display: block;
        }

        .desktop-img {
            display: none;
            width: 100%;
            height: auto;
        }
    }

    @include breakpointMax($mobile-xxsmall) {
        .mobile-img {
            display: block;
        }

        .desktop-img {
            display: none;
        }
    }

    .tag-img {
        position: absolute;
        width: 155px;
        height: 155px;
        bottom: -65px;
        left: calc(50% - 77.5px);
        cursor: pointer;

        @include breakpointMax($smaller-devices) {
            width: 124px;
            height: 124px;
            left: calc(50% - 62px);
        }
    }

    .ctn-main {
        background-color: $color-bg-offwhite;
        padding: 0 40px 40px;

        @include breakpointMax($smaller-devices) {
            padding: 0 20px 20px;
        }

        .hero-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            @include breakpointMax($smaller-devices) {
                display: block;
            }
        }

        .hero-right {
            background-image: url("../../../assets/img/mission-heroright.png");
            background-repeat: no-repeat;
            background-position: center right;
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            height: 688px;
            width: 50%;
            gap: 70px;
            padding-top: 181px;
            padding-right: 86px;
            padding-left: 20px;

            @include breakpointMax($smaller-devices) {
                background-image: none;
                background-color: $color-main;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 40px;
                width: 100%;
                gap: 0px;
            }

            .top-text {
                margin: 0 0 0 15%;

                p {
                    margin: 0;
                }

                @include breakpointMax($smaller-devices) {
                    margin-top: 0;
                    align-self: flex-start;
                }
            }

            h1.sub-hero {
                &.color-light-purple {
                    color: $color-light-purple;
                }
            }

            p {
                margin: 0px 0 121px 211px;
                color: $white;

                @include breakpointMax($smaller-devices) {
                    margin: 40px;
                    align-self: flex-end;
                }
            }
        }
    }

}