.two-column {
    background-color: $color-main;

    .ctn-main.--grid-container {
        background-color: $color-main;
        padding: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include breakpointMax($smaller-devices) {
            display: block;
            padding: 20px;
        }

        &.true {
            display: block;

            .--right-section {
                display: none;
            }

            @include breakpointMin($smaller-devices-up) {
                &:hover {
                    .--left-section {
                        .--anim-wrapper {
                            width: 100%;
                            border-radius: 80px 0 80px 0;
                        }
                    }
                }
            }
        }

        h6 {
            margin-bottom: 12px;
        }

        h3 {
            max-width: 420px;
            font-family: 'Marshalls-Sans-Light';
        }

        .button {
            max-width: fit-content;
        }

        .--left-section {
            .--anim-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                row-gap: 80px;
                padding: 40px;
                transition: width 1.5s ease-out, border-radius .2s .1s ease-out, padding-left 0.5s ease-out, background-color 0.5s ease-out;
                z-index: 1;
                position: relative;

            }

            .cta-box {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 10;
            }

            @include breakpointMin($smaller-devices-up) {
                &:hover {
                    .--anim-wrapper {
                        width: 110%;
                        border-radius: 80px 0 80px 0;
                        padding-left: 80px;
                        transition: width 1.5s ease-out, border-radius .2s ease-out, padding-left 0.5s ease-out, background-color 0.5s ease-out;
                    }

                    +.--right-section {
                        .--anim-wrapper {
                            padding-left: 110px;
                        }
                    }
                }

                +.--right-section {
                    .--anim-wrapper {
                        transition: padding-left 0.5s ease-out;
                    }
                }
            }


            &.color-darker-blue {
                .--anim-wrapper {
                    background-color: $color-darker-blue;
                }

                @include breakpointMin($smaller-devices-up) {
                    &:hover {
                        .--anim-wrapper {
                            background-color: $color-off-white;
                        }

                        h6,
                        h3 {
                            color: $color-main;
                        }
                    }
                }
            }

            h6 {
                &.color-white {
                    color: $white;
                }
            }

            h3 {
                &.color-white {
                    color: $white;
                }
            }
        }

        .--right-section {
            display: block;

            .--anim-wrapper {

                .inner {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    row-gap: 80px;
                }

                transition: border-radius .2s .1s ease-out,
                width 1.5s ease-out,
                background-color 0.5s ease-out,
                transform .2s,
                padding-left 0.5s ease-out;
                position: relative;
            }

            .cta-box {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 10;
            }

            @include breakpointMin($smaller-devices-up) {
                &:hover {
                    .--anim-wrapper {
                        z-index: 2;
                        border-radius: 0 80px 0 80px;
                        transform: translateX(-80px);
                        width: 110%;
                        transition: border-radius .2s ease-out, width 1.5s ease-out, background-color 0.5s ease-out;
                    }

                    .inner {
                        transform: translateX(-40px);
                        padding-left: 40px;
                    }
                }

                .inner {
                    transition: transform 0.5s ease-out;
                }
            }

            &.color-lightpurple {
                .--anim-wrapper {
                    background-color: $color-light-purple;

                }

                @include breakpointMin($smaller-devices-up) {
                    &:hover {
                        .--anim-wrapper {
                            background-color: $color-light-blue;
                        }
                    }
                }
            }

            h6 {
                &.color-darker-blue {
                    color: $color-darker-blue;
                }
            }

            h3 {
                &.color-main {
                    color: $color-main;
                }
            }
        }
    }
}