.terms .content {
    margin-top: 80px;
    margin-bottom: 80px;
}


.faq h3 {
    margin-top: 60px;
}

h3+.accordion-wrapper {
    margin-top: 20px;
}

.no-wrap {
    white-space: nowrap;
}

.gtm-iframe {
    display: none;
    visibility: hidden;
}
