.desc-section {
    background-color: $color-bg-offwhite;

    .ctn-main {
        background-color: $color-bg-offwhite;
        padding: 40px;

        @include breakpointMax($smaller-devices) {
            padding: 20px;
        }

        .desc-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                max-width: 756px;
                text-align: center;
                margin: 16px 0;
            }

            @include breakpointMax($smaller-devices) {
                margin-top: 40px;
            }
        }
    }
}